import './App.css';
import {
  Box,
  Stack,
  Button,
  TextField,
  Modal,
  Divider
} from '@mui/material';
import { startRegistration, startAuthentication } from '@simplewebauthn/browser';
import {useState, useRef, useEffect} from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import Input from "./components/Input";
import Feature from "./components/Feature";
import isEmail from 'validator/lib/isEmail';

const CLIENT_API_URL = process.env.REACT_APP_CLIENT_API_URL || 'http://localhost:8000';

const config = {
  baseUrl: `${CLIENT_API_URL}`,
  registerInit: `/v5/auth/register/passkey/initiate`,
  registerVerify: `/v5/auth/register/passkey`,
  authenticateInit: `/v5/auth/sessions/passkey/initiate`,
  authenticateVerify: `/v5/auth/sessions/passkey`,
  connectInit: `/v5/me/passkeys/connect`,
  connectVerify: `/v5/me/passkeys/connect`,
  myPasskeys:`/v5/me/passkeys`
}

function App() {
  const [errorMsgInput, setErrorMsgInput] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const appIdRef = useRef()
  const displayNameRef = useRef()
  const registerEmailRef = useRef()
  const authEmailRef = useRef()
  const [sid, setSid] = useState('')

  useEffect(() => {
    const storedSid = sessionStorage.getItem('sid');
    if (storedSid) {
      setSid(storedSid);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('sid', sid);
  }, [sid]);

  function checkApp() {
    const appValue = appIdRef?.current?.value
    if (!appValue) {
      setErrorMsgInput({ type: "app_id", value: "App Id must be set!" })
      return false
    }
    if (appValue && !/^[0-9a-fA-F]{32}$/.test(appValue)) {
      setErrorMsgInput({ type: 'app_id', value: "Not a valid Rave UUID!" })
      return
    }
    sessionStorage.setItem("app_id", appValue)
    setErrorMsgInput(false)
    return true
  }

  const registerWithPasskey = async ({ setIsLoading, setProcessState, setDataToShow, setErrorMsg }) => {
    if (!checkApp()) return
    const emailValue = registerEmailRef?.current?.value;
    if (!emailValue) {
      setErrorMsgInput({ type: 'register_email', value: 'Email must be set!' });
      return;
    }
    if (emailValue && !isEmail(emailValue)) {
      setErrorMsgInput({ type: 'register_email', value: "Not a valid emal!" })
      return
    }
    sessionStorage.setItem("register_email", emailValue)
    setErrorMsgInput(false)
    setErrorMsg(false)
    setIsLoading(true)
    
    const registerPayload = {
      user: {
        email: emailValue
      },
    }

    const displayName = displayNameRef.current.value
    if (displayName) {
      registerPayload.user.display_name = displayName
      sessionStorage.setItem("display_name", displayName)
    }

    setDataToShow([{ action: `Data to sent to ${config.baseUrl + config.registerInit}`, data: JSON.stringify(registerPayload, null, 2) }])
    setProcessState("Waiting for server to initiate.")
    try {
      var response = await fetch(config.baseUrl + config.registerInit, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(registerPayload),
      })
    } catch (error) {
      setErrorMsg({ type: "other", value: typeof error === 'object' && error !== null ? error.toString() : error })
      setIsLoading(false)
      return
    }

    const responseJSON = await response.json()

    if (responseJSON.error) {
      setErrorMsg({ type: "other", value: JSON.stringify(responseJSON.error) })
      setIsLoading(false)
      return
    }
    setErrorMsg("")

    setDataToShow(dataToShow => ([...dataToShow, { action: `Response from ${config.baseUrl + config.registerInit}`, data: JSON.stringify(responseJSON, null, 2) }]))
    setProcessState("Waiting for authentication.")

    try {
      var attResp = await startRegistration(responseJSON.data.options);
    } catch (error) {
      setErrorMsg({ type: "other", value: typeof error === 'object' && error !== null ? error.toString() : error })
      setIsLoading(false)
      return
    }

    const dataForVerify = {
      ...registerPayload,
      app: {
        uuid: appIdRef.current.value
      },
      device: {
        identifier: "test_api",
        name: "Test API"
      },
      state: responseJSON.data.state,
      passkey: { ...attResp },
    }

    setDataToShow(dataToShow => ([...dataToShow, { action: `Data to sent to ${config.baseUrl + config.registerVerify}`, data: JSON.stringify(dataForVerify, null, 2) }]))

    setProcessState("Waiting for server to verify.")
    try {
      const verificationResp = await fetch(config.baseUrl + config.registerVerify, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataForVerify),
      });
      var verificationRespJSON = await verificationResp.json()
      console.log("verificationResp:")
      console.log(verificationRespJSON)
      setDataToShow(dataToShow => ([...dataToShow, { action: `Response from ${config.baseUrl + config.registerVerify}`, data: JSON.stringify(verificationRespJSON, null, 2) }]))
      setIsLoading(false)
    } catch (error) {
      setErrorMsg({ type: "other", value: typeof error === 'object' && error !== null ? error.toString() : error })
      setIsLoading(false)
      return
    }
    const responseSid = verificationRespJSON?.data?.session.sid;
    if (responseSid) {
      setSid(responseSid);
      setProcessState("Success!")
    } else {
      setProcessState("Error")
    }

  }

  const authenticateUser = async ({ setIsLoading, setProcessState, setDataToShow, setErrorMsg }) => {
    if (!checkApp()) return
    const emailValue = authEmailRef?.current?.value;
    if (emailValue && !isEmail(emailValue)) {
      setErrorMsgInput({ type: 'auth_email', value: "Not a valid emal!" })
      return
    }
    sessionStorage.setItem("auth_email", emailValue)
    setErrorMsgInput(false)
    setErrorMsg(false)
    setIsLoading(true)
    const initiatePayload = {}
    if (emailValue) {
      initiatePayload.user = {
        'email': emailValue
      }
    }

    setDataToShow([{ action: `Data to sent to ${config.baseUrl + config.authenticateInit}`, data: JSON.stringify(initiatePayload, null, 2) }])
    setProcessState("Waiting for server to initiate.")
    try {
      var response = await fetch(config.baseUrl + config.authenticateInit, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify(initiatePayload),
      })
    } catch (error) {
      setErrorMsg({ type: "other", value: typeof error === 'object' && error !== null ? error.toString() : error })
      setIsLoading(false)
      return
    }

    const responseJSON = await response.json()
    console.log("responseJSONAuth:")
    console.log(responseJSON)
    setDataToShow(data => ([...data, { action: `Response from ${config.baseUrl + config.authenticateInit}`, data: JSON.stringify(responseJSON, null, 2) }]))

    if (responseJSON.error) {
      setErrorMsg({ type: "other", value: JSON.stringify(responseJSON.error) })
      setIsLoading(false)
      return
    }

    setProcessState("Waiting for authentication.")
    try {
      var asseResp = await startAuthentication(responseJSON.data.options);
    } catch (error) {
      setErrorMsg({ type: "other", value: typeof error === 'object' && error !== null ? error.toString() : error })
      setIsLoading(false)
      return
    }

    const verifyPayload = {
      app: {
        uuid: appIdRef.current.value
      },
      device: {
        identifier: "test_api",
        name: "Test API"
      },
      state: responseJSON.data.state,
      passkey: { ...asseResp}
    }

    setDataToShow(data => ([...data, { action: `Data to sent to ${config.baseUrl + config.authenticateVerify}`, data: JSON.stringify(verifyPayload, null, 2) }]))
    setProcessState("Waiting for server to verify.")
    try {
      var responseVerify = await fetch(config.baseUrl + config.authenticateVerify, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify(verifyPayload),
      })
    } catch (error) {
      setErrorMsg({ type: "other", value: typeof error === 'object' && error !== null ? error.toString() : error })
      setIsLoading(false)
      return
    }

    const responseVerifyJSON = await responseVerify.json()
    console.log("responseVerifyJSON:")
    console.log(responseVerifyJSON)
    setDataToShow(data => ([...data, { action: `Response from ${config.baseUrl + config.authenticateVerify}`, data: JSON.stringify(responseVerifyJSON, null, 2) }]))
    const responseSid = responseVerifyJSON?.data?.session.sid;
    if (responseSid) {
      setSid(responseSid);
      setProcessState("Success!")
    } else {
      setProcessState("Error")
    }

    setIsLoading(false)

  }

  const connectPasskey = async ({ setIsLoading, setProcessState, setDataToShow, setErrorMsg }) => {
    if(!sid) {
      setErrorMsg({ type: "other", value: "You must register or authenticate first!" })
      return
    }
    setErrorMsg(false)
    setIsLoading(true)

    setDataToShow([{ action: `Data to sent to ${config.baseUrl + config.connectInit}` }])
    setProcessState("Waiting for server to initiate.")
    try {
      var response = await fetch(config.baseUrl + config.connectInit, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Sid": sid,
          'Timestamp': Date.now()
        },
      })
    } catch (error) {
      setErrorMsg({ type: "other", value: typeof error === 'object' && error !== null ? error.toString() : error })
      setIsLoading(false)
      return
    }

    const responseJSON = await response.json()

    if (responseJSON.error) {
      setErrorMsg({ type: "other", value: JSON.stringify(responseJSON.error) })
      setIsLoading(false)
      return
    }
    setErrorMsg("")

    setDataToShow(dataToShow => ([...dataToShow, { action: `Response from ${config.baseUrl + config.connectInit}`, data: JSON.stringify(responseJSON, null, 2) }]))
    setProcessState("Waiting for authentication.")

    try {
      // Pass the options to the authenticator and wait for a response
      var attResp = await startRegistration(responseJSON.data.options);
    } catch (error) {
      setErrorMsg({ type: "other", value: typeof error === 'object' && error !== null ? error.toString() : error })
      setIsLoading(false)
      return
    }

    const dataForVerify = {
      state: responseJSON.data.state,
      passkey: { ...attResp },
    }

    setDataToShow(dataToShow => ([...dataToShow, { action: `Data to sent to ${config.baseUrl + config.connectVerify}`, data: JSON.stringify(dataForVerify, null, 2) }]))

    setProcessState("Waiting for server to verify.")
    try {
      const verificationResp = await fetch(config.baseUrl + config.connectVerify, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Sid': sid,
          'Timestamp': Date.now()
        },
        body: JSON.stringify(dataForVerify),
      });
      var verificationRespJSON = await verificationResp.json()
      console.log("verificationResp:")
      console.log(verificationRespJSON)
      setDataToShow(dataToShow => ([...dataToShow, { action: `Response from ${config.baseUrl + config.connectVerify}`, data: JSON.stringify(verificationRespJSON, null, 2) }]))
      setIsLoading(false)
    } catch (error) {
      setErrorMsg({ type: "other", value: typeof error === 'object' && error !== null ? error.toString() : error })
      setIsLoading(false)
      return
    }
    if (verificationRespJSON?.error) {
      setProcessState("Error")
    } else {
      setProcessState("Success!")
    }


  }

  const deletePasskey = async ({ setIsLoading, setProcessState, setDataToShow, setErrorMsg }) => {
    if(!sid) {
      setErrorMsg({ type: "other", value: "You must register or authenticate first!" })
      return
    }
    setErrorMsg(false)
    setIsLoading(true)

    setDataToShow([{ action: `Data to sent to ${config.baseUrl + config.myPasskeys}` }])
    setProcessState("Waiting for server to delete passkey.")
    try {
      var response = await fetch(config.baseUrl + config.myPasskeys, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Sid": sid,
          'Timestamp': Date.now()
        },
      })
    } catch (error) {
      setErrorMsg({ type: "other", value: typeof error === 'object' && error !== null ? error.toString() : error })
      setIsLoading(false)
      return
    }

    const responseJSON = await response.json()

    if (responseJSON.error) {
      setErrorMsg({ type: "other", value: JSON.stringify(responseJSON.error) })
      setIsLoading(false)
      return
    }
    setErrorMsg("")

    setDataToShow(dataToShow => ([...dataToShow, { action: `Response from ${config.baseUrl + config.myPasskeys}`, data: JSON.stringify(responseJSON, null, 2) }]))
    setProcessState("Success!")
    setIsLoading(false)
  }

  const fetchPasskeys = async ({ setIsLoading, setProcessState, setDataToShow, setErrorMsg }) => {
    if(!sid) {
      setErrorMsg({ type: "other", value: "You must register or authenticate first!" })
      return
    }
    setErrorMsg(false)
    setIsLoading(true)

    setDataToShow([{ action: `Data to sent to ${config.baseUrl + config.myPasskeys}` }])
    setProcessState("Waiting for server to fetch passkeys.")
    try {
      var response = await fetch(config.baseUrl + config.myPasskeys, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Sid": sid,
          'Timestamp': Date.now()
        },
      })
    } catch (error) {
      setErrorMsg({ type: "other", value: typeof error === 'object' && error !== null ? error.toString() : error })
      setIsLoading(false)
      return
    }

    const responseJSON = await response.json()

    if (responseJSON.error) {
      setErrorMsg({ type: "other", value: JSON.stringify(responseJSON.error) })
      setIsLoading(false)
      return
    }
    setErrorMsg("")

    setDataToShow(dataToShow => ([...dataToShow, { action: `Response from ${config.baseUrl + config.myPasskeys}`, data: JSON.stringify(responseJSON, null, 2) }]))
    setProcessState("Success!")
    setIsLoading(false)
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '70vh',
    overflow: 'auto'
  };

  return (
    <div className="App">
      <Stack sx={{ padding: 5, alignItems: "center" }} direction="column">
        <img src="/logo192.png" alt="Logo" style={{ height: '100px' }} />
        <Box sx={{ mb: 4 }}>Welcome to Rave Passkey Test Site!</Box>

        <div style={{ position: "absolute", top: 0, right: 0 }}>
          <Button onClick={handleOpen}>URL <SettingsIcon /></Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <Stack sx={{ alignItems: "center" }} spacing={5} direction="column">
                <TextField sx={{ width: "100%" }} label="API Base URL" variant="standard" defaultValue={config.baseUrl} onChange={(e) => config.baseUrl = e.target.value} />
                <TextField sx={{ width: "100%" }} label="Register Init Path" variant="standard" defaultValue={config.registerInit} onChange={(e) => config.registerInit = e.target.value} />
                <TextField sx={{ width: "100%" }} label="Register Verify Path" variant="standard" defaultValue={config.registerVerify} onChange={(e) => config.registerVerify = e.target.value} />
                <TextField sx={{ width: "100%" }} label="Authenticate Init Path" variant="standard" defaultValue={config.authenticateInit} onChange={(e) => config.authenticateInit = e.target.value} />
                <TextField sx={{ width: "100%" }} label="Authenticate Verify Path" variant="standard" defaultValue={config.authenticateVerify} onChange={(e) => config.authenticateVerify = e.target.value} />
                <TextField sx={{ width: "100%" }} label="Connect Init Path" variant="standard" defaultValue={config.connectInit} onChange={(e) => config.connectInit = e.target.value} />
                <TextField sx={{ width: "100%" }} label="Connect Verify Path" variant="standard" defaultValue={config.connectVerify} onChange={(e) => config.connectVerify = e.target.value} />
                <TextField sx={{ width: "100%" }} label="My Passkeys Path" variant="standard" defaultValue={config.myPasskeys} onChange={(e) => config.myPasskeys = e.target.value} />
              </Stack>
            </Box>
          </Modal>
        </div>

        <Stack direction="column" sx={{ maxWidth: 500, width: "100%" }}>
          <Input label="Rave App Id" helperText="*Required for all operations below" type="app_id" inputRef={appIdRef} errorMsg={errorMsgInput} onChange={() => checkApp()} />
        </Stack>
        {sid ? (
            <>
              <div style={{padding: '20px'}}>Authenticated with Session ID: {sid}</div>
              <Button variant="contained" sx={{color: "ff1111", bgcolor: "#ee0000", '&:hover': { bgcolor: "#bb0000" }}} onClick={() => setSid(null)}>Logout</Button>
            </>
        ) : null}
        <Divider sx={{width: "100%", mt: 6}}>Register</Divider>
        <Stack direction="column" sx={{ maxWidth: 500, width: "100%", mt: 1 }}>
          <Input label="Email" helperText="*Required" type="register_email" inputRef={registerEmailRef} errorMsg={errorMsgInput} />
          <Input label="Display Name"  type="display_name" inputRef={displayNameRef} errorMsg={errorMsgInput} />
        </Stack>
        <Feature buttonText="Register" functionToCall={registerWithPasskey} />
        <Divider sx={{ width: "100%" }}>Authenticate</Divider>
        <Stack direction="column" sx={{ maxWidth: 500, width: "100%", mt: 1 }}>
          <Input label="Email" helperText="Optional. If specified the key might be auto selected" type="auth_email" inputRef={authEmailRef} errorMsg={errorMsgInput} />
        </Stack>
        <Feature buttonText="Authenticate" functionToCall={authenticateUser} />
        <Divider sx={{ width: "100%" }}>Connect</Divider>
        <Feature buttonText="Connect" functionToCall={connectPasskey} />
        <Divider sx={{ width: "100%" }}>Fetch</Divider>
        <Feature buttonText="Fetch" functionToCall={fetchPasskeys} />
        <Divider sx={{ width: "100%" }}>Delete</Divider>
        <Feature buttonText="Delete" functionToCall={deletePasskey} />

      </Stack>
    </div >
  );
}

export default App;
